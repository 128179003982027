import CalculatorWindow from './CalculatorWindow';

const RiskCalculatorScreen = () => {
  return (
    <>
      <h1 className="article-title">Risk Battle Calculator</h1>
      <CalculatorWindow />
    </>
  );
};
export default RiskCalculatorScreen;
